@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Black.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 900;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-BlackItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Bold.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 700;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-BoldItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-ExtraBold.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 800;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 200;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-ExtraLight.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 200;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-ExtraLightItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 400;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Italic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Light.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 300;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-LightItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Medium.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 500;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-MediumItalic.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-Regular.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-SemiBold.ttf");
}
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 600;
    font-display: auto;
    src: url("../assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf");
}
