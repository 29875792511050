
    $loader-bg-color: purple;

    .fade-leave-active {
        transition: opacity 0.7s ease-in-out;
    }
    .fade-leave-to {
        opacity: 0;
    }

    .small{
        background: none;
    }

    .small .logo-color {
            stroke: purple;
        }
    .fullscreen{
        background: $loader-bg-color;
    }
    .fullscreen .logo-color {
            stroke: white;
        }

    #wanadev-loader-container {
        height: 100vh;
        width: 100vw;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1000;
    }

    #wanadev-loader-container .loader-container {
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    #wanadev-loader-container .loader-container svg {
            height: 100px;
        }

    #wanadev-loader-container .play #stroke-1 {
            animation: 1.3s opacity 0.2s infinite, 1.3s move 0.09s infinite;
        }

    #wanadev-loader-container .play #stroke-2 {
            animation: 1.3s opacity 0.4s infinite, 1.3s move 0.18s infinite;
        }

    #wanadev-loader-container .play #stroke-3 {
            animation: 1.3s opacity 0.6s infinite, 1.3s move 0.27s infinite;
        }

    #wanadev-loader-container .play #stroke-4 {
            animation: 1.3s opacity 0.8s infinite, 1.3s move 0.36s infinite;
        }

    #wanadev-loader-container .play #stroke-5 {
            animation: 1.3s opacity 1s infinite, 1.3s move 0.45s infinite;
        }

    #wanadev-loader-container .play #stroke-6 {
            animation: 1.3s opacity 1.2s infinite, 1.3s move 0.54s infinite;
        }

    #wanadev-loader-container .play #stroke-7 {
            animation: 1.3s opacity 1.4s infinite, 1.3s move 0.63s infinite;
        }

    @keyframes opacity {

            0%,
            100% {
                opacity: 1;
            }

            60% {
                opacity: 0.2;
            }
        }

    @keyframes move {

            0%,
            100% {
                transform: translateY(0);
            }

            60% {
                transform: translateY(-3%);
            }
        }

