
#tweakpane {
    position: fixed;
    right: 7rem;
    top: 1rem;
    z-index: 11;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
#tweakpane * {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
