
$width-menu: 150px;
#main-canvas {
    touch-action: none;
    height: 100%;
    width: 100%;
    background: radial-gradient(
        circle,
        rgba(251, 247, 239, 1) 33%,
        rgba(227, 227, 227, 1) 100%
    );
}
